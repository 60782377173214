export const GET_SPECIFICATIONS_CATEGORIES_REQUEST = "GET_SPECIFICATIONS_CATEGORIES_REQUEST";
export const GET_SPECIFICATIONS_CATEGORIES_SUCCESS = "GET_SPECIFICATIONS_CATEGORIES_SUCCESS";

export const SHOW_SPECIFICATIONS_CATEGORIES_REQUEST = "SHOW_SPECIFICATIONS_CATEGORIES_REQUEST";
export const SHOW_SPECIFICATIONS_CATEGORIES_SUCCESS = "SHOW_SPECIFICATIONS_CATEGORIES_SUCCESS";

export const POST_SPECIFICATIONS_CATEGORIES_REQUEST = "POST_SPECIFICATIONS_CATEGORIES_REQUEST";
export const POST_SPECIFICATIONS_CATEGORIES_SUCCESS = "POST_SPECIFICATIONS_CATEGORIES_SUCCESS";

export const PUT_SPECIFICATIONS_CATEGORIES_REQUEST = "PUT_SPECIFICATIONS_CATEGORIES_REQUEST";
export const PUT_SPECIFICATIONS_CATEGORIES_SUCCESS = "PUT_SPECIFICATIONS_CATEGORIES_SUCCESS";

export const DELETE_SPECIFICATIONS_CATEGORIES_REQUEST = "DELETE_SPECIFICATIONS_CATEGORIES_REQUEST";
export const DELETE_SPECIFICATIONS_CATEGORIES_SUCCESS = "DELETE_SPECIFICATIONS_CATEGORIES_SUCCESS";
