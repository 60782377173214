import * as ACTIONS from "@iso/redux/variationsOptions/actionTypes";

const initialState = {
  variationsOptions: {
    list: [],
    total: 0,
  },
};

function variationsOptionsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_VARIATIONS_OPTIONS_SUCCESS: {
      return {
        ...state,
        ...{ variationsOptions: payload },
      };
    }

    default:
      return state;
  }
}

export default variationsOptionsReducer;
