import * as ACTIONS from "@iso/redux/variationsValues/actionTypes";

const initialState = {
  variationsValues: {
    list: [],
    total: 0,
  },
};

function variationsValuesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_VARIATIONS_VALUES_SUCCESS: {
      return {
        ...state,
        ...{ variationsValues: payload },
      };
    }

    default:
      return state;
  }
}

export default variationsValuesReducer;
