export const GET_BRANDS_REQUEST = "GET_BRANDS_REQUEST";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";

export const SHOW_BRAND_REQUEST = "SHOW_BRAND_REQUEST";
export const SHOW_BRAND_SUCCESS = "SHOW_BRAND_SUCCESS";

export const SHOW_BRANDS_REQUEST = "SHOW_BRANDS_REQUEST";
export const SHOW_BRANDS_SUCCESS = "SHOW_BRANDS_SUCCESS";

export const POST_BRAND_REQUEST = "POST_BRAND_REQUEST";
export const POST_BRAND_SUCCESS = "POST_BRAND_SUCCESS";

export const PUT_BRAND_REQUEST = "PUT_BRAND_REQUEST";
export const PUT_BRAND_SUCCESS = "PUT_BRAND_SUCCESS";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";

export const GET_BRANDS_TYPES_REQUEST = "GET_BRANDS_TYPES_REQUEST";
export const GET_BRANDS_TYPES_SUCCESS = "GET_BRANDS_TYPES_SUCCESS";
