export const GET_VARIATIONS_VALUES_REQUEST = "GET_VARIATIONS_VALUES_REQUEST";
export const GET_VARIATIONS_VALUES_SUCCESS = "GET_VARIATIONS_VALUES_SUCCESS";

export const SHOW_VARIATIONS_VALUE_REQUEST = "SHOW_VARIATIONS_VALUE_REQUEST";
export const SHOW_VARIATIONS_VALUE_SUCCESS = "SHOW_VARIATIONS_VALUE_SUCCESS";

export const POST_VARIATIONS_VALUE_REQUEST = "POST_VARIATIONS_VALUE_REQUEST";
export const POST_VARIATIONS_VALUE_SUCCESS = "POST_VARIATIONS_VALUE_SUCCESS";

export const PUT_VARIATIONS_VALUE_REQUEST = "PUT_VARIATIONS_VALUE_REQUEST";
export const PUT_VARIATIONS_VALUE_SUCCESS = "PUT_VARIATIONS_VALUE_SUCCESS";

export const DELETE_VARIATIONS_VALUE_REQUEST = "DELETE_VARIATIONS_VALUE_REQUEST";
export const DELETE_VARIATIONS_VALUE_SUCCESS = "DELETE_VARIATIONS_VALUE_SUCCESS";
