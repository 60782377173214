import App from "@iso/redux/app/reducer";
import articlesReducer from "@iso/redux/articles/reducer";
import feedbacksReducer from "@iso/redux/feedbacks/reducer";
import reviewsReducer from "@iso/redux/reviews/reducer";
import LanguageReducer from "@iso/redux/languages/reducer";
import mediaReducer from "@iso/redux/medias/reducer";
import menuReducer from "@iso/redux/menu/reducer";
import filtersCategories from "@iso/redux/filtersCategories/reducer";
import filtersReducer from "@iso/redux/filters/reducer";
import locationsReducer from "@iso/redux/locations/reducer";
import pagesReducer from "@iso/redux/pages/reducer";
import productsCategoriesReducer from "@iso/redux/productsCategories/reducer";
import productsReducer from "@iso/redux/products/reducer";
import ordersReducer from "@iso/redux/orders/reducer";
import settingsReducer from "@iso/redux/settings/reducer";
import setionsReducer from "@iso/redux/sections/reducer";
import tagsReducer from "@iso/redux/tags/reducer";
import translatesReducer from "@iso/redux/translates/reducer";
import themeReducer from "@iso/redux/themeSwitcher/reducer";
import usersReducer from "@iso/redux/users/reducer";
import specificationsReducer from "@iso/redux/specifications/reducer";
import specificationsCategoriesReducer from "@iso/redux/specificationsCategories/reducer";
import specificationsOptionsReducer from "@iso/redux/specificationsOptions/reducer";
import variationsOptionsReducer from "@iso/redux/variationsOptions/reducer";
import variationsValuesReducer from "@iso/redux/variationsValues/reducer";
import brandsReducer from "./brands/reducer";
import { combineReducers } from "redux";

const reducers = combineReducers({
  Auth: usersReducer,
  Articles: articlesReducer,
  PagesReducer: pagesReducer,
  Tags: tagsReducer,
  Settings: settingsReducer,
  ThemeSwitcher: themeReducer,
  LanguageReducer,
  FeedbacksReducer: feedbacksReducer,
  ReviewsReducer: reviewsReducer,
  MenuReducer: menuReducer,
  MediaReducer: mediaReducer,
  ProductsCategoriesReducer: productsCategoriesReducer,
  ProductReducer: productsReducer,
  TranslateReducer: translatesReducer,
  SectionsReducer: setionsReducer,
  OrdersReducer: ordersReducer,
  FiltersCategories: filtersCategories,
  FiltersReducer: filtersReducer,
  LocationsReducer: locationsReducer,
  SpecificationsRedcuer: specificationsReducer,
  SpecificationsCategoriesReducer: specificationsCategoriesReducer,
  SpecificationsOptionsReducer: specificationsOptionsReducer,
  VariationsOptionsReducer: variationsOptionsReducer,
  VariationsValuesReducer: variationsValuesReducer,
  BrandsReducer: brandsReducer,
  App: App,
});

export default reducers;
