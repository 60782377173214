export const GET_VARIATIONS_OPTIONS_REQUEST = "GET_VARIATIONS_OPTIONS_REQUEST";
export const GET_VARIATIONS_OPTIONS_SUCCESS = "GET_VARIATIONS_OPTIONS_SUCCESS";

export const SHOW_VARIATIONS_OPTION_REQUEST = "SHOW_VARIATIONS_OPTION_REQUEST";
export const SHOW_VARIATIONS_OPTION_SUCCESS = "SHOW_VARIATIONS_OPTION_SUCCESS";

export const POST_VARIATIONS_OPTION_REQUEST = "POST_VARIATIONS_OPTION_REQUEST";
export const POST_VARIATIONS_OPTION_SUCCESS = "POST_VARIATIONS_OPTION_SUCCESS";

export const PUT_VARIATIONS_OPTION_REQUEST = "PUT_VARIATIONS_OPTION_REQUEST";
export const PUT_VARIATIONS_OPTION_SUCCESS = "PUT_VARIATIONS_OPTION_SUCCESS";

export const DELETE_VARIATIONS_OPTION_REQUEST = "DELETE_VARIATIONS_OPTION_REQUEST";
export const DELETE_VARIATIONS_OPTION_SUCCESS = "DELETE_VARIATIONS_OPTION_SUCCESS";
