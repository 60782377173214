export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";

export const SHOW_REVIEW_REQUEST = "SHOW_REVIEW_REQUEST";
export const SHOW_REVIEW_SUCCESS = "SHOW_REVIEW_SUCCESS";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";

export const PUT_REVIEW_REQUEST = "PUT_REVIEW_REQUEST";
export const PUT_REVIEW_SUCCESS = "PUT_REVIEW_SUCCESS";
