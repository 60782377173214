import * as ACTIONS from "@iso/redux/brands/actionTypes";

const initialState = {
  brands: [],
};

function brandsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case (ACTIONS.GET_BRANDS_SUCCESS, ACTIONS.SHOW_BRANDS_SUCCESS): {
      return {
        ...state,
        ...{ brands: payload.list },
      };
    }
    default:
      return state;
  }
}

export default brandsReducer;
