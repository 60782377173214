export const GET_SPECIFICATIONS_OPTIONS_REQUEST = "GET_SPECIFICATIONS_OPTIONS_REQUEST";
export const GET_SPECIFICATIONS_OPTIONS_SUCCESS = "GET_SPECIFICATIONS_OPTIONS_SUCCESS";

export const SHOW_SPECIFICATIONS_OPTIONS_REQUEST = "SHOW_SPECIFICATIONS_OPTIONS_REQUEST";
export const SHOW_SPECIFICATIONS_OPTIONS_SUCCESS = "SHOW_SPECIFICATIONS_OPTIONS_SUCCESS";

export const POST_SPECIFICATIONS_OPTIONS_REQUEST = "POST_SPECIFICATIONS_OPTIONS_REQUEST";
export const POST_SPECIFICATIONS_OPTIONS_SUCCESS = "POST_SPECIFICATIONS_OPTIONS_SUCCESS";

export const PUT_SPECIFICATIONS_OPTIONS_REQUEST = "PUT_SPECIFICATIONS_OPTIONS_REQUEST";
export const PUT_SPECIFICATIONS_OPTIONS_SUCCESS = "PUT_SPECIFICATIONS_OPTIONS_SUCCESS";

export const DELETE_SPECIFICATION_OPTIONS_REQUEST = "DELETE_SPECIFICATION_OPTIONS_REQUEST";
export const DELETE_SPECIFICATION_OPTIONS_SUCCESS = "DELETE_SPECIFICATION_OPTIONS_SUCCESS";
