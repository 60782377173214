export const GET_SPECIFICATIONS_REQUEST = "GET_SPECIFICATIONS_REQUEST";
export const GET_SPECIFICATIONS_SUCCESS = "GET_SPECIFICATIONS_SUCCESS";

export const SHOW_SPECIFICATION_REQUEST = "SHOW_SPECIFICATION_REQUEST";
export const SHOW_SPECIFICATION_SUCCESS = "SHOW_SPECIFICATION_SUCCESS";

export const POST_SPECIFICATION_REQUEST = "POST_SPECIFICATION_REQUEST";
export const POST_SPECIFICATION_SUCCESS = "POST_SPECIFICATION_SUCCESS";

export const PUT_SPECIFICATION_REQUEST = "PUT_SPECIFICATION_REQUEST";
export const PUT_SPECIFICATION_SUCCESS = "PUT_SPECIFICATION_SUCCESS";

export const DELETE_SPECIFICATION_REQUEST = "DELETE_SPECIFICATION_REQUEST";
export const DELETE_SPECIFICATION_SUCCESS = "DELETE_SPECIFICATION_SUCCESS";
