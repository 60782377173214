export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

export const SHOW_PRODUCT_REQUEST = "SHOW_PRODUCT_REQUEST";
export const SHOW_PRODUCT_SUCCESS = "SHOW_PRODUCT_SUCCESS";

export const POST_PRODUCT_REQUEST = "POST_PRODUCT_REQUEST";
export const POST_PRODUCT_SUCCESS = "POST_PRODUCT_SUCCESS";

export const PUT_PRODUCT_REQUEST = "PUT_PRODUCT_REQUEST";
export const PUT_PRODUCT_SUCCESS = "PUT_PRODUCT_SUCCESS";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";

export const REJECT_PRODUCT_REQUEST = "REJECT_PRODUCT_REQUEST";
export const REJECT_PRODUCT_SUCCESS = "REJECT_PRODUCT_SUCCESS";

export const APPROVE_PRODUCT_REQUEST = "APPROVE_PRODUCT_REQUEST";
export const APPROVE_PRODUCT_SUCCESS = "APPROVE_PRODUCT_SUCCESS";
